<template>
    <a-card>
        <a-page-header
            :title='"广告主授权"'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="广告主公司名">
                        <a-input
                            v-model="form.advertiserCompany"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="组织绑定状态">
                        <a-select
                            v-model="form.bindStatus"
                            placeholder="请选择"
                            style="width: 100%;"
                        >
                            <a-select-option key="1">自动绑定</a-select-option>
                            <a-select-option key="2">待绑定</a-select-option>
                            <a-select-option key="3">人工绑定</a-select-option>
                            <a-select-option key="4">取消绑定</a-select-option>
                            <a-select-option key="5">系统解绑</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="授权有效性">
                        <a-select
                            v-model="form.authStatus"
                            placeholder="请选择"
                            style="width: 100%;"
                        >
                            <a-select-option key="1">已授权</a-select-option>
                            <a-select-option key="2">已失效</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="广告主状态">
                        <a-select
                            v-model="form.status"
                            placeholder="请选择"
                            style="width: 100%;"
                        >
                            <a-select-option key="1">开启</a-select-option>
                            <a-select-option key="2">关闭</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <base-button
                            :title="'重置'"
                            @onClickBtn="handleReset"
                        ></base-button>
                        <base-button
                            :type="'primary'"
                            :title="'查询'"
                            @onClickBtn="handleSearch"
                        ></base-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :row-key="(record, index) => index"
            :pagination='false'
        >
            <div slot="info" slot-scope="text, record">
                <div>{{ record.oceanOrgName || '未知名称' }}</div>
                <div class="row">
                    <span class="name">id:</span>
                    <span>{{ record.oceanOrgId || '-' }}</span>
                </div>
            </div>
            <div slot="action" slot-scope="text, record">
                <a @click="jumpDetailPage(record.oceanAuthId)" v-if="record.authStatus == 1 && record.bindStatus != 1">绑定修改</a>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {
                status: '1'
            },
            principalsList: [],
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '广告主编码',
                    dataIndex: 'advertiserCode',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告主',
                    dataIndex: 'advertiserName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告主公司名',
                    dataIndex: 'advertiserCompany',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '组织绑定状态',
                    dataIndex: 'bindStatus',
                    customRender: (text, row) => {
                        let txt = '-'
                        switch(row.bindStatus) {
                            case 1:
                                txt = '自动绑定'
                                break;
                            case 2:
                                txt = <div class="red">待绑定</div>
                                break;
                            case 3:
                                txt = '人工绑定'
                                break;
                            case 4:
                                txt = '取消绑定'
                                break;
                            case 5:
                                txt = '系统解绑'
                                break;
                        }
                        return txt
                    }
                },
                {
                    align: 'center',
                    title: '授权有效性',
                    // dataIndex: 'authStatus',
                    customRender: (text, row) => {
                        let txt = <div class="red">已失效</div>
                        return row.authStatus == 1 ? '已授权' :  txt
                    }
                },
                {
                    align: 'center',
                    title: '组织名称和id',
                    scopedSlots: { customRender: 'info' }
                },
                {
                    align: 'center',
                    title: '组织角色',
                    dataIndex: 'oceanOrgRole',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '公司主体名称',
                    // dataIndex: 'oceanOrgCompanyName',
                    customRender: (text, row) => { 
                        return !row.oceanOrgCompanyName ? '-' : row.advertiserCompany == row.oceanOrgCompanyName ? <div>{row.oceanOrgCompanyName}</div>  : <div class="red">{row.oceanOrgCompanyName}</div>
                    }
                },
                {
                    align: 'center',
                    title: '组织状态',
                    // dataIndex: 'oceanSourceCompanyStatus',
                    customRender: (text, row) => { 
                        return row.oceanSourceCompanyStatus == 1 ? '审核通过' :  '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    key: 'action',
                    width: 100,
                    scopedSlots: { customRender: 'action' }
                }
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {
                status: '1'
            }
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            let params = Object.assign(this.form, {
                page: this.pagination.current,
                size: this.pagination.pageSize
            })
            this.$api.core.pphtManage.getAdvertiserOauthList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        jumpDetailPage(id) {
            this.$router.push({
                path: `/pphtManage/authorizeDetail`,
                query: {
                    id
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}
.row {
    display: flex;
    .name {
        width: 30px;
    }
}
</style>